'use client';

import { useIsAllowed } from '@/lib/RBAC';
import { cn } from '@/lib/utils';
import { Column, flexRender, Header, Row, Table } from '@tanstack/react-table';
import { CSSProperties, useEffect } from 'react';

interface Props {
  table: Table<any>;
  onClickByRow?: (rowOriginal: any) => void;
  disableRowFn?: (rowOriginal: Row<any>) => boolean;
  pinnedLastCol?: boolean;
  pinnedFirstCol?: boolean;
  className?: string;
}

export function CommonTable({ table, onClickByRow, pinnedLastCol = true, pinnedFirstCol = false, className, disableRowFn }: Props) {
  const { status, session } = useIsAllowed();
  const getCommonPinningStyles = (column: Column<any>): CSSProperties => {
    const isPinned = column.getIsPinned();

    const isLastLeftPinnedColumn = isPinned === 'left' && column.getIsLastColumn('left');
    const isFirstRightPinnedColumn = isPinned === 'right' && column.getIsFirstColumn('right');

    return {
      boxShadow: isLastLeftPinnedColumn
        ? '-4px 0 4px -4px #8f8f8f inset'
        : isFirstRightPinnedColumn
          ? '4px 0 4px -4px #8f8f8f inset'
          : undefined,
      left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
      right: isPinned === 'right' ? `${column.getAfter('right') - 1}px` : undefined,
      background: isPinned ? '#f0f4ff' : undefined,
      borderBottom: isPinned ? '2px solid #fff' : undefined,
      position: isPinned ? 'sticky' : 'relative',
      width: column.getSize(),
      zIndex: isPinned ? 1 : 0,
    };
  };

  useEffect(() => {
    function pinedProcess(headers: Header<any, unknown>[]) {
      let endIndex = headers.length - 1;
      const rightPinned = [];
      while (endIndex >= 0 && headers[endIndex].column.getCanPin()) { // From end
        rightPinned.push(table.getHeaderGroups()[0].headers[endIndex])
        endIndex--;
      }
    
      rightPinned.reverse().forEach(col => {
        col.column.pin('right'); // Pinned
      })

      let startIndex = 0;
      while (startIndex < headers.length && headers[startIndex].column.getCanPin()) { // From start
        table.getHeaderGroups()[0].headers[startIndex].column.pin('left'); // Pinned
        startIndex++;
      }
    }
    
    if (pinnedLastCol || pinnedFirstCol) {
      pinedProcess(table.getHeaderGroups()[0].headers);
    }

  }, [table, status, session]);

  if (status !== 'authenticated') {
    return null;
  }

  return (
    <table
      className={cn('w-full', className)}
      // {...{
      //   style: {
      //     width: table.getCenterTotalSize(),
      //   },
      // }}
    >
      <thead className="sticky top-0 z-[2] border-b-4 border-white bg-blue-50">
        {table.getHeaderGroups().map((headerGroup) => (
          <tr className="text-sm font-light text-gray-500" key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              const { column } = header;
              return (
                <th
                  className="h-14 px-4 pr-2 text-left font-medium"
                  style={{ minWidth: `${header.getSize()}px`, ...getCommonPinningStyles(column) }}
                  key={header.id}
                  colSpan={header.colSpan}
                >
                  {header.isPlaceholder ? null : (
                    <div
                      className={header.column.getCanSort() ? 'flex cursor-pointer select-none' : ''}
                      onClick={header.column.getToggleSortingHandler()}
                      title={
                        header.column.getCanSort()
                          ? header.column.getNextSortingOrder() === 'asc'
                            ? 'Sort ascending'
                            : header.column.getNextSortingOrder() === 'desc'
                              ? 'Sort descending'
                              : 'Clear sort'
                          : undefined
                      }
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {{
                        asc: <span className="pl-2">&#8593;</span>,
                        desc: <span className="pl-2">&#8595;</span>,
                      }[header.column.getIsSorted() as string] ?? null}
                    </div>
                  )}
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody className="z-[2]">
        {table.getRowModel().rows.map((row) => {
          return (
            <tr
              onClick={() => onClickByRow && onClickByRow(row.original)}
              key={row.id}
              className={cn(
                'h-14 min-h-14 border-2 border-white bg-blue-25 transition-colors duration-300',
                onClickByRow && 'cursor-pointer hover:border-b-blue-100 hover:bg-blue-100/50',
                disableRowFn && disableRowFn(row) && 'pointer-events-none opacity-35' 
              )}
            >
              {row.getVisibleCells().map((cell) => {
                const { column } = cell;
                return (
                  <td
                    className="px-4 text-sm text-gray-950"
                    key={cell.id}
                    style={{ ...getCommonPinningStyles(column) }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
